import i18n from 'localizations';
import { ACHServiceType } from 'models/enums/transactions/ach-service-type';

export const getAchServiceLabel = (type?: ACHServiceType) => {
  switch (type) {
    case ACHServiceType.standard:
      return i18n.t('transactionDetailsPage.labels.standardACHService');
    case ACHServiceType.sameDay:
      return i18n.t('transactionDetailsPage.labels.sameDayACHService');
    default:
      return type;
  }
};
