import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  Typography,
} from '@mui/material';
import { alpha, css, styled } from '@mui/material/styles';
import { pxToRem } from 'utils/styling-utils';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';

const DIALOG_MARGIN = 32;
const MAX_DIALOG_HEIGHT = 704;
const MAX_DIALOG_OUTER_HEIGHT = MAX_DIALOG_HEIGHT + 2 * DIALOG_MARGIN;

export const StyledButton = styled(Button)(() => ({
  width: pxToRem(135),
}));

export const StyledAddButton = styled(Button)(
  ({ theme }) => css`
    height: ${pxToRem(52)};
    background: ${alpha(theme.palette.primary.main, 0.1)};
    color: ${theme.palette.primary.main};

    @media (hover: hover) {
      &:hover {
        background: ${alpha(theme.palette.primary.main, 0.2)};
        color: ${theme.palette.primary.main};
      }
    }

    @media (hover: none) {
      &:hover {
        background: ${alpha(theme.palette.primary.main, 0.1)};
        color: ${theme.palette.primary.main};
      }
    }

    &.Mui-disabled {
      background: ${alpha(theme.palette.primary.main, 0.1)};
      color: ${theme.palette.primary.main};
    }
  `,
);

export const StyledListItem = styled(ListItem)`
  margin-bottom: 32px;
`;

export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    width: pxToRem(460),
    margin: pxToRem(DIALOG_MARGIN),
    height: `calc(100% - ${2 * DIALOG_MARGIN}px)`,
  },
  [`@media screen and (min-height: ${MAX_DIALOG_OUTER_HEIGHT}px)`]: {
    [`& .${dialogClasses.paper}`]: {
      maxHeight: pxToRem(MAX_DIALOG_HEIGHT),
    },
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)} 0`,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: `${pxToRem(theme.gaps._24px + theme.gaps._10px)}`,
  paddingBottom: `${pxToRem(theme.gaps._56px / 2)}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  right: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledBackIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  left: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
  color: '#BABABA',
}));

export const StyledLoadingButtonWithSkeleton = styled(LoadingButtonWithSkeleton)(() => ({
  width: pxToRem(135),
}));

export const StyledTypographyCaption = styled(Typography)(() => ({
  textAlign: 'center',
  width: '100%',
}));
