import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import i18next from 'i18next';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { API_BASE_URL } from 'redux/api/api-routes';
import { ErrorDataPayload } from 'redux/api/types';
import { MessageType, setBannerData } from 'redux/banner';
import { Auth0ClientSingleton } from 'auth0/auth0-client-singleton';
import {
  CANNOT_APPROVE_PAYMENT_IN_STATUS_REJECTED,
  EXCEED_USER_LIMIT,
  EXCEED_TRANSACTION_LIMIT,
  PAYMENT_ALREADY_REJECTED,
  JWT_TOKEN_IS_ALREADY_IN_USE,
  CA_OR_IDENTITY_SUSPENDED,
  IDENTITY_IS_NOT_VALID,
  ACTIVATE_ALREADY_ACTIVATED_CA_OR_IDENTITY_IS_NOT_VALID,
  SUSPEND_ALREADY_SUSPENDED_CA_OR_IDENTITY_IS_NOT_VALID,
  TRANSFER_IS_FORBIDDEN_FOR_ASSET_PAIRS,
  WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_ACCOUNT,
  WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_TRANSACTION,
  DEFAULT_ERROR_CODE_ELEMENTS,
  BALANCE_OF_WALLET_FOR_NATIVE_COIN_IS_NOT_SUFFICIENT,
  PAGE_SIZE_MUST_BE_LESS_OR_EQUAL_THAN_5000,
  FUNCTIONALITY_IS_NOT_AVAILABLE_FOR_ORGANIZATION,
} from './error-codes';
import {
  CREATE_USER_TAG,
  DELETE_USER_BY_ID_TAG,
  EDIT_USER_TAG,
  APPROVE_PAYMENT_TAG,
  REJECT_PAYMENT_TAG,
  GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID,
  GET_USER_BY_ID_TAG,
  GET_USERS_TAG,
  GET_BUSINESS_IDENTITY_TAG,
  GET_PERSONAL_IDENTITY_TAG,
  GET_CUSTODIAL_ACCOUNTS,
} from './constants';

const LOGIN_REQUIRED = 'login_required';

const mainBaseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: async headers => {
    let auth0: Auth0Client | undefined;
    try {
      auth0 = await Auth0ClientSingleton.getInstance();
      const accessToken = await auth0.getTokenSilently();
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }
    } catch (error) {
      if (error.error === LOGIN_REQUIRED) {
        await auth0?.logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return headers;
    }
  },
});

const mainQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await mainBaseQuery(args, api, extraOptions);

  if (!navigator.onLine) {
    api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('offlineNetworkMessage') }));
    return result;
  }

  if (result.error && result.error.status === 400) {
    // @TODO: use error code once BE is ready
    const data = result.error.data as ErrorDataPayload;
    if (data?.type === 'https://tools.ietf.org/html/rfc7231#section-6.5.1') {
      api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverError.validationError') }));
      return result;
    }

    api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverError.badRequest') }));
    return result;
  }

  if (result.error && result.error.status === 403) {
    api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('accessForbiddenMessage') }));
    return result;
  }

  if (result.error && result.error.status === 404) {
    api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverError.notFound') }));
    return result;
  }

  if (result.error && result.error.status === 409) {
    api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverError.thisItemAlreadyExist') }));
    return result;
  }
  if (result.error && result.error.status === 422) {
    const data = result.error.data as ErrorDataPayload;
    if (data.errors?.[EXCEED_USER_LIMIT]) {
      api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverError.limitsError') }));
      return result;
    }

    if (data.errors?.[TRANSFER_IS_FORBIDDEN_FOR_ASSET_PAIRS]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.transferIsForbiddenForAssetPairs'),
        }),
      );
      return result;
    }

    if (data.errors?.[WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_ACCOUNT]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.withdrawalAmountIsLowerRequiredMinAccountBalance'),
        }),
      );
      return result;
    }

    if (data.errors?.[WITHDRAWAL_AMOUNT_IS_LOWER_REQUIRED_MIN_TRANSACTION]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.withdrawalAmountIsLowerRequiredMinTransactionBalance'),
        }),
      );
      return result;
    }

    if (data.errors?.[DEFAULT_ERROR_CODE_ELEMENTS]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.somethingWentWrong'),
        }),
      );
      return result;
    }

    if (data.errors?.[BALANCE_OF_WALLET_FOR_NATIVE_COIN_IS_NOT_SUFFICIENT]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.balanceOfWalletForNativeCoinIsNotSufficient'),
        }),
      );
      return result;
    }

    if (data.errors?.[PAGE_SIZE_MUST_BE_LESS_OR_EQUAL_THAN_5000]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.pageSizeMustBeLessOrEqualThan500'),
        }),
      );
      return result;
    }

    if (data.errors?.[EXCEED_TRANSACTION_LIMIT]) {
      api.dispatch(
        setBannerData({ type: MessageType.error, message: i18next.t('serverError.transactionlimitsError') }),
      );
      return result;
    }

    if (data.errors?.[PAYMENT_ALREADY_REJECTED] || data.errors?.[CANNOT_APPROVE_PAYMENT_IN_STATUS_REJECTED]) {
      api.dispatch(
        setBannerData({ type: MessageType.error, message: i18next.t('serverError.paymentAlreadyRejected') }),
      );
      return result;
    }

    if (data.errors?.[PAYMENT_ALREADY_REJECTED] || data.errors?.[JWT_TOKEN_IS_ALREADY_IN_USE]) {
      api.dispatch(
        setBannerData({ type: MessageType.error, message: i18next.t('serverError.jwtTokenIsAlreadyInUse') }),
      );
      return result;
    }

    if (data.errors?.[CA_OR_IDENTITY_SUSPENDED]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('backendErrorMessages.tradingOrTransferringIsForbiddenForSuspendedAccounts'),
        }),
      );
      return result;
    }

    if (
      data.errors?.[IDENTITY_IS_NOT_VALID] ||
      data.errors?.[ACTIVATE_ALREADY_ACTIVATED_CA_OR_IDENTITY_IS_NOT_VALID] ||
      data.errors?.[SUSPEND_ALREADY_SUSPENDED_CA_OR_IDENTITY_IS_NOT_VALID]
    ) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('backendErrorMessages.actionIsForbidden'),
        }),
      );
      return result;
    }

    if (data.errors?.[FUNCTIONALITY_IS_NOT_AVAILABLE_FOR_ORGANIZATION]) {
      api.dispatch(
        setBannerData({
          type: MessageType.error,
          message: i18next.t('serverError.functionalityIsNotAvailableForOrganization'),
        }),
      );
      return result;
    }
  }

  // set error banner when server doesn't respond;
  // @TODO Refactor this implicit catch all case
  if (result.error && result.error.status !== 403) {
    if (result.error.status === 409 || result.error.status === 401) {
      // Email is already in use
      return result;
    }

    api.dispatch(setBannerData({ type: MessageType.error, message: i18next.t('serverErrorBanner') }));
    return result;
  }

  return result;
};

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: mainQueryWithReauth,
  tagTypes: [
    CREATE_USER_TAG,
    EDIT_USER_TAG,
    DELETE_USER_BY_ID_TAG,
    APPROVE_PAYMENT_TAG,
    REJECT_PAYMENT_TAG,
    GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID,
    GET_USER_BY_ID_TAG,
    GET_USERS_TAG,
    GET_BUSINESS_IDENTITY_TAG,
    GET_PERSONAL_IDENTITY_TAG,
    GET_CUSTODIAL_ACCOUNTS,
  ],
  endpoints: () => ({}),
});
