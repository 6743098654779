import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { List, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AccountBeneficiaryRequestModel } from 'models/request/custodial-accounts/account-beneficiary-request-model';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Column } from 'components/shared/Flex/Column';
import { Gap } from 'components/shared/Gap';
import { ReactComponent as ApproveIcon } from 'assets/icons/white-checkmark.svg';
import NoBeneficiaries from './NoBeneficiaries';
import {
  StyledAddButton,
  StyledButton,
  StyledListItem,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  StyledLoadingButtonWithSkeleton,
  StyledTypographyCaption,
  StyledBackIconButton,
} from './styled';
import BeneficiaryItem from '../BeneficiaryItem';

const MAX_BENEFICIARIES = 5;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  beneficiaries: Array<AccountBeneficiaryRequestModel>;
  onDone: () => void;
  onBack: () => void;
  onAddBeneficiary: () => void;
  onEditBeneficiary: (email: string) => void;
  isLoading: boolean;
};

const BeneficiariesListModal: React.FC<Props> = ({
  isOpen,
  onClose,
  beneficiaries,
  onBack,
  onAddBeneficiary,
  onEditBeneficiary,
  onDone,
  isLoading,
}) => {
  const { t } = useTranslation();
  const numberOfOwners = beneficiaries.length;

  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <StyledDialogTitle>
        <StyledBackIconButton onClick={onBack}>
          <KeyboardBackspaceIcon />
        </StyledBackIconButton>
        <Column>
          <Gap size="_24px" />
          <Typography variant="h4">{t('identityDetailsPage.addAccountModal.beneficiariesTitle')}</Typography>
        </Column>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column>
          <Gap size="_12px" />
          <StyledTypographyCaption variant="bodyDefaultBook" color="text.secondary">
            {t('identityDetailsPage.addAccountModal.beneficiariesCaption')}
          </StyledTypographyCaption>
          <Gap size="_20px" />
          <Stack gap="32px">
            {numberOfOwners < MAX_BENEFICIARIES && (
              <StyledAddButton onClick={onAddBeneficiary}>
                <Stack gap="16px" direction="row">
                  <AddIcon />
                  <Typography variant="buttonLarge" color="inherit">
                    {t('identityDetailsPage.addAccountModal.addBeneficiary')}
                  </Typography>
                </Stack>
              </StyledAddButton>
            )}

            {numberOfOwners ? (
              <List disablePadding>
                {beneficiaries.map(owner => (
                  <StyledListItem key={owner.email} disablePadding>
                    <BeneficiaryItem
                      firstName={owner.firstName}
                      lastName={owner.lastName}
                      email={owner.email}
                      onClick={onEditBeneficiary}
                    />
                  </StyledListItem>
                ))}
              </List>
            ) : (
              <NoBeneficiaries />
            )}
          </Stack>
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('identityDetailsPage.addAccountModal.cancel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_4px" />
        <StyledLoadingButtonWithSkeleton
          endIcon={<ApproveIcon />}
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {t('identityDetailsPage.addAccountModal.create')}
            </Typography>
          }
          color="primary"
          onClick={onDone}
          loading={isLoading}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default BeneficiariesListModal;
