import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document as _Document, Page as _Page, Text as _Text, View as _View } from '@react-pdf/renderer';
import { WireInstructionsState } from 'elements/element-transfer/types';
import { ParticipantSection } from './ParticipantSection';
import { PDFTemplateStyles as styles } from './styled';

const View: any = _View;
const Document: any = _Document;
const Page: any = _Page;
const Text: any = _Text;

type Props = {
  wireInstructions: WireInstructionsState;
};

export const PDFInstructionsTemplate: React.FC<Props> = ({
  wireInstructions: { accountNumber, routingNumber, accountHolder, bank, swiftCode, refCode },
}) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>{t('elements.transfer.customizableLabels.depositWireInstructionsPageTitle')}</Text>

        <Text style={styles.sectionTitle}>
          {t('elements.transfer.depositWireInstructionsPage.accountNumberFieldLabel')}
        </Text>
        <Text style={styles.sectionText}>{accountNumber}</Text>

        <Text style={styles.sectionTitle}>
          {t('elements.transfer.depositWireInstructionsPage.routingNumberFieldLabel')}
        </Text>
        <Text style={styles.sectionText}>{routingNumber}</Text>

        <Text style={styles.sectionTitle}>
          {t('elements.transfer.depositWireInstructionsPage.swiftCodeFieldLabel')}
        </Text>
        <Text style={styles.sectionText}>{swiftCode}</Text>

        {refCode && (
          <>
            <Text style={styles.sectionTitle}>
              {t('elements.transfer.depositWireInstructionsPage.refCodeFieldLabel')}
            </Text>
            <Text style={styles.sectionText}>{refCode}</Text>
          </>
        )}

        <ParticipantSection
          header={t('elements.transfer.depositWireInstructionsPage.accountHolderSectionHeader')}
          participantInfo={accountHolder}
        />

        <View style={styles.participantDivider} />

        <ParticipantSection
          header={t('elements.transfer.depositWireInstructionsPage.bankDetailsSectionHeader')}
          participantInfo={bank}
        />
      </Page>
    </Document>
  );
};
