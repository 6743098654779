import Button from '@mui/material/Button';
import { alpha, css, styled } from '@mui/material/styles';
import { Dialog, DialogActions, dialogClasses, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { colors } from 'elements/theme';
import { pxToRem } from 'utils/styling-utils';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';

const DIALOG_MARGIN = 32;
const MAX_DIALOG_HEIGHT = 704;
const MAX_DIALOG_OUTER_HEIGHT = MAX_DIALOG_HEIGHT + 2 * DIALOG_MARGIN;
export const StyledRemoveButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    padding: 15px 10px;

    @media (hover: hover) {
      &:hover {
        background: ${alpha(colors.grey200, 0.2)};
      }
    }

    @media (hover: none) {
      &:hover {
        background: transparent;
      }
    }
  `,
);

export const StyledBackIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  left: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
  color: '#BABABA',
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
  paddingTop: `${pxToRem(theme.gaps._24px + theme.gaps._10px)}`,
  paddingBottom: `${pxToRem(theme.gaps._56px / 2)}`,
}));

export const StyledLoadingButtonWithSkeleton = styled(LoadingButtonWithSkeleton)(({ theme }) => ({
  width: '100%',
  margin: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledForwardIcon = styled(ArrowForwardIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
}));

export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    width: pxToRem(460),
    margin: pxToRem(DIALOG_MARGIN),
    height: `calc(100% - ${2 * DIALOG_MARGIN}px)`,
  },
  [`@media screen and (min-height: ${MAX_DIALOG_OUTER_HEIGHT}px)`]: {
    [`& .${dialogClasses.paper}`]: {
      maxHeight: pxToRem(MAX_DIALOG_HEIGHT),
    },
  },
}));
