import i18n from 'localizations';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { IdentityDetailsNode } from 'ui-interfaces/identity-details/identity-details-node';
import { IIdentityDetailsPageHeader } from 'ui-interfaces/identity-details/i-identity-details-page-header';
import { IIdentityDetailsPageTitleRow } from 'ui-interfaces/identity-details/i-identity-details-page-title-row';
import { ShortUserResponseModel } from 'models/response/users/short-user-response-model';
import { getIdentityTypeLabel } from 'utils/labels-mapping/get-identity-type-label';
import { formatDate } from 'utils/format-date';
import { checkIfAccessAllowed } from 'utils/sub-apis/check-if-access-allowed';
import { mapIdentityStatusModelToStatusCellProps } from 'utils/multi-mapping/statuses/map-identity-status-model-to-status-cell-props';
import { IdentityStatusModel } from 'models/enums/identities/identity-status-model';
import { KycLevel } from 'models/enums/identities/kyc-level';
import { IdentityDetailsBaseResponseModel } from 'models/response/identities/identity-details-base-response-model';
import { SubApiCases } from 'navigation/sub-api-cases';
import { calculateIsSuspensionActionShown } from 'utils/identities/calculate-is-suspension-action-shown';
import { featureFlags } from 'utils/feature-flags';

export class IdentityDetailsBaseViewModel implements IIdentityDetailsPage {
  pageTitle: IIdentityDetailsPageTitleRow = {
    title: '',
    statusCellProps: mapIdentityStatusModelToStatusCellProps(IdentityStatusModel.active),
    kycLevel: KycLevel.L0,
  };

  readonly pageHeader: IIdentityDetailsPageHeader;

  readonly sectionHeader = i18n.t('identityDetailsPage.identityDetailsTitle');

  nodes: IdentityDetailsNode[] = [];

  readonly isCustodialAccountsTabShown: boolean;

  readonly isWalletsTabShown: boolean;

  readonly isSuspensionActionShown: boolean;

  readonly isSuspendMode: boolean;

  readonly isActive: boolean;

  constructor(identity: IdentityDetailsBaseResponseModel, user: ShortUserResponseModel) {
    this.pageHeader = {
      typeLabel: getIdentityTypeLabel(identity.type),
      id: identity.id,
      createdAt: formatDate(identity.createdAt),
      modifiedAt: identity.modifiedAt ? formatDate(identity.modifiedAt) : '',
    };

    this.isCustodialAccountsTabShown = user.organization?.allowedSubApis
      ? checkIfAccessAllowed(
          [SubApiCases.trust, SubApiCases.trustCompliance, SubApiCases.trustWalletCompliance, SubApiCases.default],
          user.organization.allowedSubApis,
        )
      : false;

    this.isWalletsTabShown = user.organization?.allowedSubApis
      ? checkIfAccessAllowed(
          [SubApiCases.wallet, SubApiCases.walletCompliance, SubApiCases.trustWalletCompliance, SubApiCases.default],
          user.organization.allowedSubApis,
        ) && featureFlags.isWalletEnabled
      : false;

    this.isSuspensionActionShown = calculateIsSuspensionActionShown(identity.status, identity.isSuspendedByIntegrator);
    this.isSuspendMode = identity.status !== IdentityStatusModel.suspended;
    this.isActive = identity.status === IdentityStatusModel.active;
  }
}
