import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl, InputLabel, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BaseTextField } from 'components/shared/inputs/BaseTextField';
import { ReactComponent as CloseIcon } from 'assets/icons/grey-close-icon.svg';
import { Gap } from 'components/shared/Gap';
import { Column } from 'components/shared/Flex/Column';
import { useYupRules } from 'hooks/use-yup-rules';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { getCustodialAccountTypeLabel } from 'utils/labels-mapping/get-custodial-account-type-label';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';
import { CustodialAccountType } from 'models/enums/custodial-accounts/custodial-account-type';
import { getCustodialAccountLabel } from 'utils/labels-mapping/get-custodial-account-label';
import { getIsIra } from 'utils/custodial-accounts/beneficiaries/get-is-ira';
import { CreateCustodianAccountRequestModel } from 'models/request/custodial-accounts/create-custodial-account-request-model';
import { useLazyGetCustodialAccountAgreementUrlQuery } from 'redux/api/api-custodial-accounts';
import { useLoader } from 'hooks/use-loader';
import { ReactComponent as ApproveIcon } from 'assets/icons/white-checkmark.svg';
import { IdentityType } from 'models/enums/identities/identity-type';
import {
  StyledButton,
  StyledCenteredTextTypography,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledForm,
  StyledFormControlLabel,
  StyledForwardIcon,
  StyledIconButton,
  StyledLoadingButtonWithSkeleton,
  StyledMenuItem,
  StyledSelect,
  StyledTypographyCaption,
} from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formValues: CreateCustodianAccountRequestModel) => void;
  onContinue: (formValues: CreateCustodianAccountRequestModel) => void;
  name: string;
  isLoading: boolean;
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  initialData?: CreateCustodianAccountRequestModel;
  agreementCheck: boolean;
  updateAgreementCheck: Dispatch<SetStateAction<boolean>>;
};

export const AddAccountModal: React.FC<Props> = ({
  isOpen,
  onClose,
  name,
  urlParams,
  onSubmit,
  onContinue,
  isLoading,
  initialData,
  agreementCheck,
  updateAgreementCheck,
}) => {
  const { t } = useTranslation();
  const { accountNameRule, requiredStringRule } = useYupRules();
  const [getCustodialAccountAgreementTrigger, custodialAccountAgreementResult] =
    useLazyGetCustodialAccountAgreementUrlQuery();
  const [agreementUrl, setAgreementUrl] = useState('/');
  const onAgreementCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => updateAgreementCheck(e.target.checked);

  const isBusinessIdentity = urlParams.identityType === IdentityType.business;

  const fetchCustodialAccountAgreement = useCallback(
    () => getCustodialAccountAgreementTrigger(undefined),
    [getCustodialAccountAgreementTrigger],
  );

  useEffect(() => {
    if (isOpen) {
      fetchCustodialAccountAgreement();
    }
  }, [fetchCustodialAccountAgreement, isOpen]);

  useEffect(() => {
    if (custodialAccountAgreementResult.isSuccess) {
      setAgreementUrl(custodialAccountAgreementResult.data.url);
    } else if (custodialAccountAgreementResult.isError) {
      onClose();
    }
  }, [custodialAccountAgreementResult, onClose]);

  const isLoadingAgreement = custodialAccountAgreementResult.isFetching;
  useLoader(isLoadingAgreement);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    submitForm,
    validateForm,
    resetForm,
    isValid,
  } = useFormik({
    initialValues: {
      accountType:
        initialData?.type || (isBusinessIdentity ? CustodialAccountType.business : CustodialAccountType.personal),
      accountName: initialData?.name || '',
      accountLabel: initialData?.label || CustodialAccountLabel.user,
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      accountType: requiredStringRule,
      accountName: accountNameRule,
    }),
    onSubmit: (onSubmitValues, { setSubmitting }) => {
      const submitFunction = getIsIra(onSubmitValues?.accountType) ? onContinue : onSubmit;
      submitFunction({
        type: onSubmitValues.accountType,
        name: onSubmitValues.accountName,
        label: onSubmitValues.accountLabel,
        businessIdentityId:
          onSubmitValues.accountType === CustodialAccountType.business ? urlParams.identityId : undefined,
        personalIdentityId:
          onSubmitValues.accountType !== CustodialAccountType.business ? urlParams.identityId : undefined,
      });
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!isOpen) {
      resetForm({
        values: {
          accountType:
            initialData?.type || (isBusinessIdentity ? CustodialAccountType.business : CustodialAccountType.personal),
          accountName: initialData?.name || '',
          accountLabel: initialData?.label || CustodialAccountLabel.user,
        },
      });
      validateForm();
    }
  }, [initialData, isBusinessIdentity, isOpen, resetForm, validateForm]);

  return (
    <StyledDialog open={isOpen && custodialAccountAgreementResult.isSuccess} onClose={onClose}>
      <StyledDialogTitle>
        <Column>
          <Gap size="_24px" />
          <Typography variant="h4">{t('identityDetailsPage.addAccountModal.title')}</Typography>
        </Column>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <Column>
          <Gap size="_12px" />
          <StyledTypographyCaption variant="bodyDefaultBook" color="text.secondary">
            {t('identityDetailsPage.addAccountModal.caption')}
          </StyledTypographyCaption>
          <Gap size="_24px" />
          <StyledCenteredTextTypography variant="h5">{name}</StyledCenteredTextTypography>
          <StyledCenteredTextTypography variant="buttonMedium" color="text.secondary">
            {t('identityDetailsPage.addAccountModal.identityId')}
          </StyledCenteredTextTypography>
          <StyledCenteredTextTypography variant="bodyDefaultBook" color="text.secondary">
            {urlParams.identityId}
          </StyledCenteredTextTypography>
          <Gap size="_32px" />
          <StyledForm onSubmit={handleSubmit}>
            <FormControl>
              <InputLabel>{t('identityDetailsPage.addAccountModal.accountType')}</InputLabel>
              <StyledSelect
                name="accountType"
                IconComponent={ExpandMoreIcon}
                value={values.accountType}
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('identityDetailsPage.addAccountModal.accountType')}
              >
                {Object.entries(CustodialAccountType)
                  .filter(([value]) => {
                    return !(
                      (value !== CustodialAccountType.business && isBusinessIdentity) ||
                      (value === CustodialAccountType.business && !isBusinessIdentity)
                    );
                  })
                  .map(([key, value]) => (
                    <StyledMenuItem value={value} key={key}>
                      {getCustodialAccountTypeLabel(value)}
                    </StyledMenuItem>
                  ))}
              </StyledSelect>
            </FormControl>
            <Gap size="_32px" />
            <BaseTextField
              name="accountName"
              value={values.accountName}
              label={t('identityDetailsPage.addAccountModal.accountNameLabel')}
              error={!!errors.accountName && touched.accountName}
              helperText={!!errors.accountName && touched.accountName ? errors.accountName : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Gap size="_32px" />
            <FormControl>
              <InputLabel>{t('identityDetailsPage.addAccountModal.accountLabel')}</InputLabel>
              <StyledSelect
                name="accountLabel"
                IconComponent={ExpandMoreIcon}
                value={values.accountLabel}
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('identityDetailsPage.addAccountModal.accountLabel')}
              >
                {Object.entries(CustodialAccountLabel).map(([key, value]) => (
                  <StyledMenuItem value={value} key={key}>
                    {getCustodialAccountLabel(value)}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
            <Gap size="_32px" />
            <StyledFormControlLabel
              control={<Checkbox onChange={onAgreementCheckboxChange} checked={agreementCheck} />}
              label={
                <>
                  <Typography variant="bodyDefaultMedium">
                    {t('identityDetailsPage.addAccountModal.agreementLabel')}
                  </Typography>
                  <Link target="_blank" rel="noopener noreferrer" href={agreementUrl}>
                    <Typography variant="bodyDefaultMedium" color="primary">
                      {t('identityDetailsPage.addAccountModal.linkLabel')}
                    </Typography>
                  </Link>
                </>
              }
            />
          </StyledForm>
        </Column>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton variant="outlined" size="large" onClick={onClose}>
          <Typography variant="buttonMedium">{t('identityDetailsPage.addAccountModal.cancel')}</Typography>
        </StyledButton>
        <Gap isHorizontal size="_4px" />
        <StyledLoadingButtonWithSkeleton
          endIcon={getIsIra(values.accountType) ? <StyledForwardIcon /> : <ApproveIcon />}
          variant="contained"
          size="large"
          text={
            <Typography variant="buttonMedium" color="background.paper">
              {getIsIra(values.accountType)
                ? t('identityDetailsPage.addAccountModal.continue')
                : t('identityDetailsPage.addAccountModal.create')}
            </Typography>
          }
          disabled={!isValid || !agreementCheck}
          color="primary"
          onClick={submitForm}
          loading={isLoading}
        />
      </StyledDialogActions>
    </StyledDialog>
  );
};
