import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import SuspendIcon from '@mui/icons-material/DoDisturb';
import { StyledButton } from './styled';

type Props = {
  isSuspendMode: boolean;
  onClick: () => void;
};

export const SuspensionAction: React.FC<Props> = ({ isSuspendMode, onClick }) => {
  const { t } = useTranslation();

  if (isSuspendMode) {
    return (
      <StyledButton variant="outlined" size="large" startIcon={<SuspendIcon color="action" />} onClick={onClick}>
        <Typography variant="buttonMedium">{t('identityDetailsPage.suspendActions.suspend')}</Typography>
      </StyledButton>
    );
  }

  return (
    <StyledButton variant="contained" size="large" color="error" startIcon={<SuspendIcon />} onClick={onClick}>
      <Typography variant="buttonMedium" color="background.paper">
        {t('identityDetailsPage.suspendActions.unSuspend')}
      </Typography>
    </StyledButton>
  );
};
