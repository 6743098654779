import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { mainApi } from 'redux/api/main-api';
import { API_ROUTES } from 'redux/api/api-routes';
import { CollectionResponse, Paginated } from 'redux/api/types';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_FOR_AUTO_HEIGHT,
  FIRST_PAGE,
  GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID,
  GET_CUSTODIAL_ACCOUNTS,
} from 'redux/api/constants';
import { CustodialAccountResponseModel } from 'models/response/custodial-accounts/custodial-account-response-model';
import { CustodialAccountExtendedResponseModel } from 'models/response/custodial-accounts/custodial-account-extended-response-model';
import { CustodialAccountBalanceModel } from 'models/response/custodial-accounts/custodial-account-balance-model';
import { FilterResult } from 'filters/interfaces/filter-result';
import { CustomAssetBalanceResponseModel } from 'models/response/custodial-accounts/custom-asset-balance-response-model';
import { AccountBeneficiaryResponseModel } from 'models/response/custodial-accounts/account-beneficiary-response-model';
import { ElementsJwtTypeModel } from 'models/enums/identities/elements-jwt-type-model';
import { JwtResponseModel } from 'models/response/identities/jwt-response-model';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { CustodialAccountLabel } from 'models/enums/custodial-accounts/custodial-account-label';
import { UpdateCustodialAccountDetailRequestModel } from 'models/request/custodial-accounts/update-custodial-account-detail-request-model';
import { UpdateAccountStatusModel } from 'models/request/custodial-accounts/update-account-status-model';
import { CoinDepositInstructionsModel } from 'models/response/current/coin-deposit-instructions-model';
import { CreateCustodianAccountRequestModel } from 'models/request/custodial-accounts/create-custodial-account-request-model';

export interface CustodialAccountsApiParams extends Paginated {
  ownerIdentityId?: string;
  filterResult?: FilterResult;
}

// @TODO: name according to naming conventions 'CustodialAccountsBalancesApiParams'
export interface AccountsBalancesParams extends Paginated {
  custodialAccountId: string;
  filterResult?: FilterResult;
}
export interface AccountsBalancesElementsParams {
  custodialAccountId: string;
}

export interface CustodialAccountCustomAssetBalanceApiParams extends Paginated {
  custodialAccountId: string;
  filterResult?: FilterResult;
}

export interface BeneficiariesByAccountIdApiParams {
  custodialAccountId: string;
}

export interface JwtForElementApiParams {
  custodialAccountId: string;
  elementType: ElementsJwtTypeModel;
}

export interface UpdateCustodialAccountDetailApiParams {
  custodialAccountId: string;
  requestData: UpdateCustodialAccountDetailRequestModel;
  hasViewCorporatePermission: boolean;
}

export interface VerifyAccountApiParams {
  custodialAccountId: string;
  updateAccountStatusModel: UpdateAccountStatusModel;
}

export interface CustodialAccountCoinsDepositApiParams {
  custodialAccountId: string;
  network: string;
  asset: string;
}

const custodialAccountsApi = mainApi.injectEndpoints({
  endpoints: build => ({
    getCustodialAccounts: build.query<CollectionResponse<CustodialAccountResponseModel>, CustodialAccountsApiParams>({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, ownerIdentityId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;
          return {
            url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
            params: {
              page,
              pageSize,
              [`filter[ownerIdentityId eq]`]: ownerIdentityId,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
          params: {
            page,
            pageSize,
            [`filter[ownerIdentityId eq]`]: ownerIdentityId,
          },
        };
      },
      providesTags: [GET_CUSTODIAL_ACCOUNTS],
    }),
    getTwoCustodialAccounts: build.query<CollectionResponse<CustodialAccountResponseModel>, undefined>({
      query: () => ({
        url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
        params: {
          pageSize: 2,
        },
      }),
    }),
    getUserCustodialAccounts: build.query<
      CollectionResponse<CustodialAccountResponseModel>,
      CustodialAccountsApiParams
    >({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, ownerIdentityId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;
          return {
            url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
            params: {
              page,
              pageSize,
              [`filter[ownerIdentityId eq]`]: ownerIdentityId,
              [`filter[${property} ${operator}]`]: value.trim(),
              [`filter[label eq]`]: CustodialAccountLabel.user,
            },
          };
        }
        return {
          url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
          params: {
            page,
            pageSize,
            [`filter[ownerIdentityId eq]`]: ownerIdentityId,
            [`filter[label eq]`]: CustodialAccountLabel.user,
          },
        };
      },
    }),
    getCorporateCustodialAccounts: build.query<
      CollectionResponse<CustodialAccountResponseModel>,
      CustodialAccountsApiParams
    >({
      query: ({ page = FIRST_PAGE, pageSize = DEFAULT_PAGE_SIZE, ownerIdentityId, filterResult }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;
          return {
            url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
            params: {
              page,
              pageSize,
              [`filter[ownerIdentityId eq]`]: ownerIdentityId,
              [`filter[${property} ${operator}]`]: value.trim(),
              [`filter[label eq]`]: CustodialAccountLabel.corporate,
            },
          };
        }
        return {
          url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
          params: {
            page,
            pageSize,
            [`filter[ownerIdentityId eq]`]: ownerIdentityId,
            [`filter[label eq]`]: CustodialAccountLabel.corporate,
          },
        };
      },
    }),
    getCustodialAccountDetailsById: build.query<CustodialAccountExtendedResponseModel, string>({
      query: (custodialAccountId: string) => ({
        url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT, { custodialAccountId }),
      }),
      keepUnusedDataFor: 0,
      providesTags: [GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID],
    }),
    updateCustodialAccountDetails: build.mutation<undefined, UpdateCustodialAccountDetailApiParams>({
      query: ({ custodialAccountId, requestData }) => ({
        url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT, { custodialAccountId }),
        method: 'PATCH',
        body: requestData,
      }),
      invalidatesTags: (
        _,
        err: FetchBaseQueryError | undefined,
        params: UpdateCustodialAccountDetailApiParams,
      ): any => {
        if (err) {
          return undefined;
        }

        if (!params.hasViewCorporatePermission && params.requestData.label === CustodialAccountLabel.corporate) {
          return undefined;
        }

        return [GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID];
      },
    }),
    createCustodialAccount: build.mutation<undefined, CreateCustodianAccountRequestModel>({
      query: (data: CreateCustodianAccountRequestModel) => ({
        url: API_ROUTES.private.CUSTODIAL_ACCOUNTS,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [GET_CUSTODIAL_ACCOUNTS],
    }),
    getCustodialAccountAgreementUrl: build.query<{ url: string }, undefined>({
      query: () => ({
        url: API_ROUTES.private.CUSTODIAL_ACCOUNT_AGREEMENT,
      }),
    }),
    getAccountBalancesById: build.query<CollectionResponse<CustodialAccountBalanceModel>, AccountsBalancesParams>({
      query: ({
        page = FIRST_PAGE,
        pageSize = DEFAULT_PAGE_SIZE_FOR_AUTO_HEIGHT,
        custodialAccountId,
        filterResult,
      }) => {
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;
          return {
            url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_BALANCES, { custodialAccountId }),
            params: {
              page,
              pageSize,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_BALANCES, { custodialAccountId }),
          params: {
            page,
            pageSize,
          },
        };
      },
    }),
    getAccountBalancesByIdElements: build.query<
      CollectionResponse<CustodialAccountBalanceModel>,
      AccountsBalancesElementsParams
    >({
      query: ({ custodialAccountId }) => {
        return {
          url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_BALANCES, { custodialAccountId }),
        };
      },
    }),
    getCustodialAccountCustomAssetBalanceById: build.query<
      CollectionResponse<CustomAssetBalanceResponseModel>,
      CustodialAccountCustomAssetBalanceApiParams
    >({
      query: ({
        page = FIRST_PAGE,
        pageSize = DEFAULT_PAGE_SIZE_FOR_AUTO_HEIGHT,
        custodialAccountId,
        filterResult,
      }) => {
        const url = getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_CUSTOM_ASSET_BALANCES, {
          custodialAccountId,
        });
        if (filterResult) {
          const { property, operator, value } = filterResult as FilterResult;
          return {
            url,
            params: {
              page,
              pageSize,
              [`filter[${property} ${operator}]`]: value.trim(),
            },
          };
        }
        return {
          url,
          params: {
            page,
            pageSize,
          },
        };
      },
    }),
    getBeneficiariesByAccountId: build.query<
      CollectionResponse<AccountBeneficiaryResponseModel>,
      BeneficiariesByAccountIdApiParams
    >({
      query: ({ custodialAccountId }) => {
        const url = getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_BENEFICIARIES, { custodialAccountId });

        return {
          url,
        };
      },
    }),
    getJwtForElement: build.query<JwtResponseModel, JwtForElementApiParams>({
      query: ({ custodialAccountId, elementType }) => ({
        url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_JWT, {
          custodialAccountId,
          elementType,
        }),
      }),
    }),
    verifyCustodialAccount: build.mutation<undefined, VerifyAccountApiParams>({
      query: ({ custodialAccountId, updateAccountStatusModel }) => ({
        url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_VERIFY, { custodialAccountId }),
        method: 'POST',
        body: updateAccountStatusModel,
      }),
      invalidatesTags: [GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID],
    }),
    getCustodialAccountCoinsDepositInstructions: build.query<
      CoinDepositInstructionsModel,
      CustodialAccountCoinsDepositApiParams
    >({
      query: ({ custodialAccountId, network, asset }) => ({
        url: getRouteWithParams(API_ROUTES.private.CUSTODIAL_ACCOUNT_CRYPTO_DEPOSIT_INSTRUCTIONS, {
          custodialAccountId,
          network,
          asset,
        }),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetCustodialAccountsQuery,
  useLazyGetTwoCustodialAccountsQuery,
  useLazyGetUserCustodialAccountsQuery,
  useLazyGetCorporateCustodialAccountsQuery,
  useLazyGetCustodialAccountDetailsByIdQuery,
  useLazyGetAccountBalancesByIdQuery,
  useLazyGetCustodialAccountCustomAssetBalanceByIdQuery,
  useLazyGetBeneficiariesByAccountIdQuery,
  useLazyGetJwtForElementQuery,
  useUpdateCustodialAccountDetailsMutation,
  useCreateCustodialAccountMutation,
  useLazyGetCustodialAccountAgreementUrlQuery,
  useVerifyCustodialAccountMutation,
  useLazyGetCustodialAccountCoinsDepositInstructionsQuery,
  useLazyGetAccountBalancesByIdElementsQuery,
} = custodialAccountsApi;
