export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_PAGE_SIZE_FOR_AUTO_HEIGHT = 20;
export const FIRST_PAGE = 1;

export const DEFAULT_CREATED_AT_LAST_DAYS_FILTER = 7;

/**
 * Redux TAGS constants
 * @name Name CONSTANT as endpoint in capitalize with 'TAG' ending and put into constant value endpoint origin name
 * @example export const APPROVE_PAYMENT_TAG = 'approvePayment';
 */
export const CREATE_USER_TAG = 'createUser';
export const EDIT_USER_TAG = 'editUser';
export const DELETE_USER_BY_ID_TAG = 'deleteUserById';
export const APPROVE_PAYMENT_TAG = 'approvePayment';
export const REJECT_PAYMENT_TAG = 'rejectPayment';
export const GET_CUSTODIAL_ACCOUNT_DETAILS_BY_ID = 'getCustodialAccountDetailsById';
export const GET_CUSTODIAL_ACCOUNTS = 'getCustodialAccounts';
export const GET_USER_BY_ID_TAG = 'getUserById';
export const GET_USERS_TAG = 'getUsers';
export const GET_BUSINESS_IDENTITY_TAG = 'getBusinessIdentityDetailsById';
export const GET_PERSONAL_IDENTITY_TAG = 'getPersonalIdentityDetailsById';
