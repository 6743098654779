import { css, styled } from '@mui/material/styles';
import { alpha } from '@mui/material';
import { ReactComponent as OwnersIcon } from 'assets/icons/beneficiaries/ownersRounded.svg';
import { Stack } from 'elements/components';
import { colors } from 'elements/theme';

export const StyledStack = styled(Stack)(
  () => css`
    padding: 16px 28px;
    border: 1px solid ${colors.grey200};
    border-radius: 8px;
  `,
);

export const StyledOwnersIcon = styled(OwnersIcon)(
  () => css`
    color: ${colors.grey600};

    #ownersRoundedBg {
      fill: ${alpha(colors.grey200, 0.6)};
    }
  `,
);
