import { Paginated } from 'elements/types';
import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';

export enum TransferDirectionEnum {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  DepositWithdrawal = 'Deposit+Withdrawal',
}

export enum TransferFlow {
  Deposit = 'depositFlow',
  Withdrawal = 'withdrawalFlow',
}

export enum TransferOptionsEnum {
  Ach = 'ach',
  Crypto = 'crypto',
  Wire = 'wire',
}

export type ParticipantAddress = {
  postalCode: string;
  street1: string;
  state: string;
  city: string;
  street2?: string;
  country?: string;
};

export type ParticipantAddressFormData = Required<ParticipantAddress>;

export type ParticipantInfo = ParticipantAddress & {
  name: string;
};

export type WireInstructionsState = {
  accountNumber: string;
  routingNumber: string;
  accountHolder: ParticipantInfo;
  bank: ParticipantInfo;
  swiftCode: string;
  refCode: string;
};

export type WireDepositInstructionsResponse = {
  accountNumber?: string;
  routingNumber?: string;
  receiverName?: string;
  receiverAddress?: Partial<ParticipantAddress>;
  receiverBankName?: string;
  receiverBankAddress?: Partial<ParticipantAddress>;
  swiftCode?: string;
  refCode?: string;
};

export enum WireLocationType {
  Domestic = 'domestic',
  International = 'international',
}

export type WireRecipientInfoFormData = ParticipantAddressFormData & {
  fullName: string;
  locationType: WireLocationType;
};

export type WireWithdrawalAdditionalDetails = {
  note: string;
  memo: string;
  purposeOfPayment: string;
};

export type WireBankInfoFormData = ParticipantAddressFormData & {
  bankName: string;
  intermediaryBankAbaRoutingNumber?: string;
  intermediaryBankName?: string;
};

export type WireAccountNumbersFormData = {
  routingNumber: string;
  swiftCode: string;
  accountNumber: string;
  confirmAccountNumber: string;
};

export type InternalRecipientInfoFormData = {
  name: string;
  accountNumber: string;
};

export type DepositCoin = { assetType: string; network: string };

export type WithdrawalCoin = DepositCoin & {
  balance: number;
};

export type CryptoAddressResponse = {
  id: string;
  asset: string;
  network: string;
  address: string;
  destinationTag?: string;
  name: string;
  createdAt: string;
};

export type CryptoAddressRequest = {
  asset: string;
  network: string;
  address: string;
  destinationTag?: string;
  name: string;
};

export type GetBankInfoResponse = { bankName: string };

export interface ExternalAccountsApiParams extends Paginated {
  'filter[searchInfo cont]'?: string;
  'filter[type in]'?: string;
  identityId: string;
}

export interface CustodialAccountsApiParams extends Paginated {
  'filter[status eq]'?: CustodialAccountStatus;
  'filter[searchInfo cont]'?: string;
}

export interface CryptoAddressesApiParams extends Paginated {
  'filter[searchInfo cont]'?: string;
  'filter[network eq]'?: string;
  'filter[asset eq]'?: string;
}

export interface IInlineErrorBanner {
  error: boolean;
  errorMessage?: string;
  errorTraceId?: string;
}
