import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyledOwnersIcon, StyledStack } from './styled';

const NoBeneficiaries = () => {
  const { t } = useTranslation();

  return (
    <StyledStack gap="12px" alignCenter>
      <StyledOwnersIcon />
      <Typography variant="subtitleMedium" color="primary.font" textAlign="center">
        {t('identityDetailsPage.addAccountModal.noBeneficiaries')}
      </Typography>
    </StyledStack>
  );
};

export default NoBeneficiaries;
