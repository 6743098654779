import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { StyledItemButton, StyledLeftContainer, StyledRightContainer } from './styled';

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  onClick: (email: string) => void;
};

const BeneficiaryItem: React.FC<Props> = ({ firstName, lastName, email, onClick }) => {
  const { t } = useTranslation();

  const handleEdit = () => {
    onClick(email);
  };

  return (
    <StyledItemButton onClick={handleEdit} disableRipple>
      <>
        <StyledLeftContainer>
          <Typography variant="bodyDefaultMedium" color="primary.font">
            {`${firstName} ${lastName}`}
          </Typography>
          <Typography variant="bodyDefaultBook" color="text.secondary">
            {email}
          </Typography>
        </StyledLeftContainer>
        <StyledRightContainer>
          <Typography variant="buttonSmall" color="primary">
            {t('identityDetailsPage.addAccountModal.edit')}
          </Typography>
        </StyledRightContainer>
      </>
    </StyledItemButton>
  );
};

export default BeneficiaryItem;
