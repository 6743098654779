import { PathMatch } from 'react-router-dom';
import i18n from 'localizations';
import { formatDate } from 'utils/format-date';
import { CustodialAccountsDetailsHeader } from 'ui-interfaces/custodial-accounts-details/custodial-accounts-details-header';
import { addSpaceAfterEvery4Chars } from 'utils/add-space-after-every-4-chars';
import { CustodialAccountExtendedResponseModel } from 'models/response/custodial-accounts/custodial-account-extended-response-model';
import { get4LastChars } from 'utils/get-4-last-chars';
import { getCustodialAccountTypeLabel } from 'utils/labels-mapping/get-custodial-account-type-label';
import { getIsIra } from 'utils/custodial-accounts/beneficiaries/get-is-ira';
import { StatusCellProps } from 'ui-interfaces/statuses/status-cell-props';
import { mapCustodialAccountStatusToStatusCellProps } from 'utils/multi-mapping/statuses/map-custodial-account-status-to-status-cell-props';
import { CustodialAccountStatus } from 'models/enums/custodial-accounts/custodial-account-status';
import { getCustodialAccountLabel } from 'utils/labels-mapping/get-custodial-account-label';
import { checkIfTradeAndTransferButtonsShown } from 'utils/custodial-accounts/details/check-if-trade-and-transfer-buttons-shown';
import { formAccountOwnerUrl } from 'utils/custodial-accounts/details/form-account-owner-url';
import { calculateIsSuspensionActionShown } from 'utils/custodial-accounts/details/calculate-is-suspension-action-shown';

export class CustodialAccountsDetailsHeaderViewModel implements CustodialAccountsDetailsHeader {
  readonly custodialAccountId: string = '';

  readonly custodialAccountNumber: string = '';

  readonly owner: string = '';

  readonly ownerDetailsUrl: string = '';

  readonly identityId: string = '';

  readonly dateOpened: string = '';

  readonly title: string = '';

  readonly modifiedAt: string = '';

  readonly accountTypeLabel: string = '';

  readonly isBeneficiariesTabShown: boolean = false;

  readonly isPrivateSecurityTabShown: boolean = false;

  readonly statusCellProps: StatusCellProps = mapCustodialAccountStatusToStatusCellProps(CustodialAccountStatus.open);

  readonly accountName: string = '';

  readonly accountLabel: string = '';

  readonly areTradeAndTransferButtonsShown: boolean = false;

  readonly isSuspendMode: boolean = false;

  readonly isSuspensionActionShown: boolean = false;

  readonly isSuspensionBannerShown: boolean = false;

  constructor(pathMatch: PathMatch | null, response: CustodialAccountExtendedResponseModel) {
    this.custodialAccountId = response.id;
    this.custodialAccountNumber = addSpaceAfterEvery4Chars(response.number);
    this.owner = response.ownerIdentityDisplayName;
    this.ownerDetailsUrl = formAccountOwnerUrl(response, pathMatch);
    this.identityId = response.ownerIdentityId;
    this.dateOpened = formatDate(response.createdAt);
    this.title = `${i18n.t('custodialAccountDetailsPage.headerTitle')} *${get4LastChars(response.number)}`;
    this.modifiedAt = response.modifiedAt ? formatDate(response.modifiedAt) : '';
    this.accountTypeLabel = getCustodialAccountTypeLabel(response.type);
    this.isBeneficiariesTabShown = getIsIra(response.type);
    this.isPrivateSecurityTabShown = response.hasPrivateSecurity;
    this.statusCellProps = mapCustodialAccountStatusToStatusCellProps(
      response.status,
      !response.isSuspendedByIntegrator,
    );
    this.accountName = response.name ?? '';
    this.accountLabel = getCustodialAccountLabel(response.label).toUpperCase();
    this.areTradeAndTransferButtonsShown = checkIfTradeAndTransferButtonsShown({
      status: response.status,
      isOwnerSuspended: response.isOwnerSuspended,
    });
    this.isSuspendMode = response.status !== CustodialAccountStatus.suspended;

    this.isSuspensionActionShown = calculateIsSuspensionActionShown(
      response.status,
      response.isSuspendedByIntegrator,
      response.isOwnerSuspended,
    );

    this.isSuspensionBannerShown = response.isOwnerSuspended;
  }
}
