import {
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControlLabel,
  formControlLabelClasses,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LoadingButtonWithSkeleton } from 'components/shared/buttons/LoadingButtonWithSkeleton';
import { pxToRem } from 'utils/styling-utils';

const DIALOG_MARGIN = 32;
const MAX_DIALOG_HEIGHT = 704;
const MAX_DIALOG_OUTER_HEIGHT = MAX_DIALOG_HEIGHT + 2 * DIALOG_MARGIN;
export const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    width: pxToRem(460),
    margin: pxToRem(DIALOG_MARGIN),
    height: `calc(100% - ${2 * DIALOG_MARGIN}px)`,
  },
  [`@media screen and (min-height: ${MAX_DIALOG_OUTER_HEIGHT}px)`]: {
    [`& .${dialogClasses.paper}`]: {
      maxHeight: pxToRem(MAX_DIALOG_HEIGHT),
    },
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `${pxToRem(theme.gaps._40px)} ${pxToRem(theme.gaps._40px)} 0`,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: `0 ${pxToRem(theme.gaps._40px)}`,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: `${pxToRem(theme.gaps._24px + theme.gaps._10px)}`,
  paddingBottom: `${pxToRem(theme.gaps._56px / 2)}`,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: pxToRem(theme.gaps._20px),
  right: pxToRem(theme.gaps._20px),
  height: pxToRem(36),
  width: pxToRem(36),
  padding: 0,
}));

export const StyledLoadingButtonWithSkeleton = styled(LoadingButtonWithSkeleton)(() => ({
  width: pxToRem(135),
}));

export const StyledButton = styled(Button)(() => ({
  width: pxToRem(135),
}));

export const StyledTypographyCaption = styled(Typography)(() => ({
  textAlign: 'center',
  width: '100%',
}));

export const StyledCenteredTextTypography = styled(Typography)(() => ({
  textAlign: 'center',
}));

export const StyledForm = styled('form')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  width: '100%',
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...theme.typography.bodyDefaultBook,
  padding: pxToRem(theme.gaps._12px),
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  [`& .${formControlLabelClasses.label}`]: {
    marginLeft: pxToRem(theme.gaps._12px),
  },
}));

export const StyledForwardIcon = styled(ArrowForwardIcon)(({ theme }) => ({
  color: theme.palette.background.paper,
}));
