import React from 'react';
import { Stack } from '@mui/material';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { usePermissionBasedAccess } from 'hooks/use-permission-based-access';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { KycLevel } from 'models/enums/identities/kyc-level';
import { SuspensionAction } from '../SuspensionAction';
import { LoadingTopRow } from '../LoadingHeader/LoadingTopRow';
import { StyledTopRow } from './styled';
import { AddAccountAction } from '../AddAccountAction';

type Props = {
  breadCrumbs: BreadCrumbsItems;
  showSuspensionModal: () => void;
  showAddAccountModal: () => void;
  data?: IIdentityDetailsPage;
  loading: boolean;
};

export const IdentityDetailsPageTopRow: React.FC<Props> = ({
  breadCrumbs,
  showSuspensionModal,
  data,
  loading,
  showAddAccountModal,
}) => {
  const hasIdentitySuspenderPermission = usePermissionBasedAccess([DashboardRoles.identitySuspender]);
  const hasAccountCreationPermission = usePermissionBasedAccess([DashboardRoles.caEditor]);

  if (loading || !data) {
    return <LoadingTopRow breadCrumbs={breadCrumbs} />;
  }

  return (
    <StyledTopRow>
      <Breadcrumbs items={breadCrumbs} />
      <Stack direction="row">
        {hasIdentitySuspenderPermission && data?.isSuspensionActionShown && (
          <SuspensionAction isSuspendMode={data?.isSuspendMode ?? false} onClick={showSuspensionModal} />
        )}
        {hasAccountCreationPermission && data?.pageTitle.kycLevel !== KycLevel.L0 && data?.isActive && (
          <AddAccountAction onClick={showAddAccountModal} />
        )}
      </Stack>
    </StyledTopRow>
  );
};
