import i18n from 'localizations';
import { WireInstructionsState } from 'elements/element-transfer/types';
import { getWireAddressLine3 } from 'elements/element-transfer/utils/getWireAddressLine3';

export const composeWireInstructionsShareText = (wireInstructions: WireInstructionsState) => {
  return `${i18n.t('elements.transfer.depositWireInstructionsPage.share.wireInstructionsFor', {
    name: wireInstructions.accountHolder.name,
  })}\n
${i18n.t('elements.transfer.depositWireInstructionsPage.share.accountNumber', {
  number: wireInstructions.accountNumber,
})}
${i18n.t('elements.transfer.depositWireInstructionsPage.share.routingNumber', {
  number: wireInstructions.routingNumber,
})}
${i18n.t('elements.transfer.depositWireInstructionsPage.share.swiftCode', {
  code: wireInstructions.swiftCode,
})}
${i18n.t('elements.transfer.depositWireInstructionsPage.share.refCode', {
  code: wireInstructions.refCode,
})}\n
${i18n.t('elements.transfer.depositWireInstructionsPage.share.sendWireTo')}
${wireInstructions.accountHolder.name}
${
  wireInstructions.accountHolder.street2
    ? `${wireInstructions.accountHolder.street1}\n${wireInstructions.accountHolder.street2}`
    : wireInstructions.accountHolder.street1
}
${getWireAddressLine3(
  wireInstructions.accountHolder.city,
  wireInstructions.accountHolder.state,
  wireInstructions.accountHolder.postalCode,
)}\n
${i18n.t('elements.transfer.depositWireInstructionsPage.share.bankDetails')}
${wireInstructions.bank.name}
${
  wireInstructions.bank.street2
    ? `${wireInstructions.bank.street1}\n${wireInstructions.bank.street2}`
    : wireInstructions.bank.street1
}
${getWireAddressLine3(wireInstructions.bank.city, wireInstructions.bank.state, wireInstructions.bank.postalCode)}`;
};
