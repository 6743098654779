import { Box, Button } from '@mui/material';
import { alpha, css, styled } from '@mui/material/styles';
import { colors } from 'elements/theme';

export const StyledItemButton = styled(Button)(
  () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border: 1px solid ${colors.grey200};
    border-radius: 8px;
    width: 100%;
    gap: 4px;
    height: 100%;

    &.Mui-disabled {
      border-color: ${colors.grey200};
    }

    @media (hover: hover) {
      &:hover {
        background: ${alpha(colors.grey200, 0.2)};
      }
    }

    @media (hover: none) {
      &:hover {
        background: transparent;
      }
    }
  `,
);

export const StyledRightContainer = styled(Box)`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  gap: 4px;
`;

export const StyledLeftContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 auto;
  min-width: 0;
  text-align: start;
`;
